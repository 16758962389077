import { FormArray, FormArrayName, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { LocaleCode } from "../config";

export interface IAuthUser {
  id: string;
  uuid: string;
  username: string;
  channelId: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  roles: Array<IUserRole>;
  channelProductRole: UserProductRole;
  pref?: IUserPref;
}

export interface IAuthUserSession extends IAuthUser {
  channelId: number;
  session: {
    access_token: string;
    environment: string;
    token_expiry: string | Date;
    token_refresh_time: string | Date;
    token_time: string | Date;
    refreshLock?: number;
  };
  storeUrl: string;
  store: IUserRole;
}

export interface IAuthSessionStorage {
  token: string;
  token_time: string | Date;
  token_refresh_time: string | Date;
  expires: Date;
  channelId: number;
  role: string;
  refreshLock?: number;
}

export interface IAuthResponse {
  access_token: string;
  token_type: string;
}

export interface IUserRole {
  label: string;
  name: string;
  role: string;
  channelId: number;
  staging?: string;
  url?: string;
}

export interface IQuery {
  [key: string]: string;
}

export interface IQueryParams {
  limit: number;
  offset?: number;
  skip?: number;
  count?: number;
  search?: any | Object;
  customSearch?: {
    keys: string[];
    input: string;
  };
  filter?:
    | string
    | Array<{ key: string; value: string | number | string[] | number[] }>;
  exact?: string | Array<{ key: string; value: string | number }>;
  displayGroup?: string;
  S002?: string;
  deliveryMethod?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  direction?: string;
  order?: string | string[];
  sort?: string;
  [key: string]: any;
}

export interface IProductQueryParams extends IQueryParams {
  A007?: number;
}

export interface IUnits {
  id: number;
  volume_title: string;
  reference_unit: string;
  volume_conversion: number;
  C000?: string;
  C001?: number;
}

export interface IStore {
  id: string;
  label: string;
  staging?: string;
  production?: any;
}

export enum HttpRespone {
  SUCCESS = 200,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  UNAUTHENTICATED = 403,
  INTERNAL_SERVER = 500,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

type CollicoErrorType =
  | "ParameterMissing"
  | "ParameterInvalid"
  | "HandlingStarted"
  | "ApiKeyMismatch"
  | "DeliveryMethodLock";

export interface ICollicoError {
  type: CollicoErrorType;
  data: string;
  message: string;
}

export interface ISiteConfig {
  title: string;
  tenant: string;
  merchantId: number;
  displaygroup: {
    hierarchyLevel: number;
  };
  side_nav?: {
    enable_menus?: string[];
    disableMenus?: string[];
  };
  lang?: LocaleCode;
  product?: {
    accessLevel: UserProductRole;
  };
}

export interface IChannel {
  id: number;
  title: string;
  slug: string;
  configPath: string;
}

export interface IMenu {
  slug: string;
  label: string;
  labelKey?: string;
  url: string;
  icon: string;
  visible: boolean;
  accessBy?: GrantRoleType[];
}

export interface IDataResponse {
  status: boolean;
  msg: string;
  msgTitle?: string;
}

export interface ISortParams {
  value: {
    direction: string;
    order: string;
    query: object;
  };
  query: object;
}

export type SchemaInputType =
  | "textfield"
  | "textarea"
  | "number"
  | "bool"
  | "select"
  | "date"
  | "datetime"
  | "time-picker"
  | "tags"
  | "file"
  | "multilevel-select-display-group"
  | "dropdown"
  | "inputGroup"
  | "panel"
  | "checkbox-group"
  | "form-array"
  | "checkbox";

export type SchemaDataType = "string" | "int" | "intText";

export type SchemaInputDisplayFormat = "price";

export interface ISchemaField {
  id?: undefined;
  key: string;
  ref: string;
  type: SchemaInputType;
  dataType: SchemaDataType;
  displayFormat: SchemaInputDisplayFormat;
  label: string;
  labelKey?: string;
  placeholder?: string;
  placeholderKey?: string;
  isLabelRequired?: boolean;
  default: any;
  data: ISchemaOptionValue;
  required?: boolean;
  protected?: boolean;
  disabled?: boolean;
  hidden: boolean;
  validate?: boolean;
  validators?: ISchemaValidator[];
  watchValidity?: any[];
  format_type?: string;
  components?: ISchemaField[];
  missingPropValue?: any;
  value?: any; //using for files upload
  prop?: string;
  maxLength?: number;
  minLength?: number;
  clearable?: boolean;
  castInput?: boolean;
  prefix?: string | number;
  suffix?: string | number;
  resolveTo: [{ key: string; type: SchemaInputType; format_type?: string }];
  ignore?: boolean;
  multiSubField: {
    includeNamedKey: string;
    keys: Array<{ key: string }>;
  };
  multiValue: boolean;
  containerClass?: string;
  normalized?: boolean;
  isVisibleTo?: number[];
  isHiddenFrom?: number[];
}

export interface ISchemaFieldType {
  [key: string]: ISchemaField;
}

export interface IFormGroupSchema {
  formGroup: FormGroup;
  schema: ISchemaFieldType;
  schemaControls: ISchemaField[];
  errorNotify?: { msg: string; title?: string };
  filteredSchema: ISchemaFieldType;
  patchValue: (data: any, opt?: { default: true }) => void;
  patchFormArrayControl: (data: any, opt?: { default: true }) => void;
  setFieldValue: (key: string, value: any) => void;
  setFieldOption: (
    key: string,
    option: Partial<ISchemaField>,
    shouldPatch?: boolean
  ) => void;
  updateFieldOption: (
    key: string,
    option: { prop: string; value: any }
  ) => void;
  hideField: (
    key: string,
    option?: { default?: any; hideValidation?: boolean }
  ) => void;
  setValidators: (
    key: string,
    validatorFn: ISchemaValidator | ISchemaValidator[]
  ) => void;
  resolveDataFromSchema: (
    data: any,
    resolverSchema?: Partial<ISchemaField>
  ) => any;
  schemaFieldCallback: (fn: Function) => void;
  removeField: (key: string | string[]) => void;
  validateForm: () => void;
}

export interface IFormGroupSchemaMeta {
  errorNotify?: { msg: string; title?: string };
}

export interface IExtraFieldOption {
  protected?: boolean;
  data?: ISchemaOptionValue;
}

export interface IFormArrayItemExtraFieldOption {
  [key: string]: IExtraFieldOption;
}

export interface ISchemaOptionValue {
  values: Array<{
    label: string;
    value: any;
    extra?: any;
    channelVisibility?: number[];
    channelHidden?: number[];
  }>;
  defaultOptValue?: any;
  hideNoSelectOpt?: boolean;
  url?: string;
}

export interface ISchemaValidator {
  callback: any;
  callbackName?: string;
  length?: number;
  value?: any;
  msg?: string;
  requiredSchema?: boolean;
  isHiddenFrom?: any[];
}

export interface IBaseDateInput extends moment.MomentInputObject {}

export enum UserRole {
  ADMIN = "admin",
  USER = "bouser",
}

export enum GrantRoleType {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum UserProductRole {
  PRODUCT_METADATA = "PRODUCT_METADATA",
  PRODUCT_ASSORTMENT = "PRODUCT_ASSORTMENT",
  CHANNEL_ASSORTMENT = "CHANNEL_ASSORTMENT",
}

export interface IHttpApiResponse<T> {
  meta: {
    limit: number;
    total: number;
  };
  result: IHttpResult<T> | IHttpResult<T>[];
  status: number;
}

export interface IAuthorizeUserResponse {
  meta: {
    user: IAuthUser;
  };
  result: IHttpResult<unknown>[];
  status: number;
}

export interface IHttpResult<T> {
  id?: number;
  objectName: string;
  properties: T;
}

export interface IFormArraySchemaChangeEvent {
  rowIndex: number;
  key: string;
  formGroup: FormGroup;
  formArray: FormArray;
}

export interface IRouteMeta {
  breadcrumb: string;
  accessBy: GrantRoleType[];
}

export type ILocaleText = {
  [key in LocaleCode]: string;
};

export interface IAppConfig {
  apiUrl: string;
  environment: "production" | "staging";
  prefix: string;
  cookieTimeoutSeconds: number;
  authKey: string;
  sessionLocaleKey: string;
  lastActivityKey: string;
  cacheRequestTimeoutMins: number;
  defaultLocale: string;
  tokenRenewalInterval: number;
  idleInterval: number;
  maxDropdownOptions: number;
}


export type IUserPref = {
  id?: number;
  user_uuid: string;
  last_preferred_lang : LocaleCode | null;
}
