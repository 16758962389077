import { Injectable } from "@angular/core";
import { filter, map } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";

import * as moment from "moment";
import { ToastrService } from "ngx-toastr";

import { Utils } from "src/app/commons/utils/utils";
import { BreadcrumbService } from "angular-crumbs";
import { ActivatedRouteSnapshot } from "@angular/router";
import { SchemaOptionValue } from "../ITypes";
import { BaseEvent, BaseEventCallback } from "src/app/commons/ITypes";

@Injectable()
export class GeneralService {
  private subject$ = new Subject<BaseEvent>();

  // defaults
  months: string[] = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];

  constructor(
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService
  ) {}

  // commons
  generateURL(url: string) {
    if (url) {
      return url.toLowerCase().replace(" ", "-");
    }
  }

  sumTotalItems(products) {
    return products.reduce((a: number, b) => Number(a) + Number(b.quantity), 0);
  }

  getFullMonth(month: number) {
    if (month === null || month === undefined) {
      return "N/A";
    }
    return this.months[month - 1];
  }

  formatDateTimeToUTC(date, parseUTC = true) {
    if (date) {
      const d = new Date(date);

      if(parseUTC){
        return moment.utc(date).local().format("LLL");
      }

      return moment(d).format("LLL");
    }
  }

  parseUTCToLocalFormat(val,format=null) {
    return moment.utc(val).local().format(format || "YYYY-MM-DDTHH:mm:ss");
  }

  parseLocalToUTCFormat(val,format=null) {
    return moment(val).utc().format(format || "YYYY-MM-DDTHH:mm:ss");
  }

  formatDateToUTC(date) {
    if (date) {
      const d = new Date(date);
      return moment(d).format("LL");
    }
  }

  formatDateToString(date) {
    if (date) {
      const d = new Date(date);
      return moment(d).format("DD.MM.YYYY");
    }
  }

  formatTimeToUTC(date, parseUTC = true) {
    if (date) {
      const d = new Date(date);

      if (parseUTC) {
        return moment.utc(date).local().format("LT");
      }

      return moment(d).format("LT");
    }
  }

  parseDateFormat(str, format, defaultFormat = null) {
    defaultFormat = defaultFormat || ["YYYY-MM-DD"];
    return moment(str, defaultFormat).format(format);
  }

  parseDateTimeToUTC(val) {
    return moment(val).utc().format();
  }

  isFutureDate(val) {
    const currentDateVal = moment().format("YYYY-MM-DD");
    return moment(val) >= moment(currentDateVal);
  }

  generateImageThumbnailUrl(url: string, ext?: string) {
    try {
      if (!url) {
        return "assets/default-placeholder.png";
      }
      const urlExt = url.split(".").pop();
      const thumbExt = ext ? ext : `-xs.${urlExt}`;
      return url.replace(/\.[^.]+$/, thumbExt);
    } catch (error) {
      this.toastr.error(error.message, "Generate Thumbnail");
      return url;
    }
  }

  parseAmount(amount: number, splitBy: string = ",") {
    if (!amount) {
      if (amount != 0) {
        return "-";
      }
      return amount;
    }
    const _amount = Number(amount).toFixed(2);
    return _amount.replace(".", splitBy);
  }

  handleIsEmail(str: string) {
    if (!str) {
      return false;
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
  }

  handleIsPhoneNumber(str: string) {
    if (!str) {
      return false;
    }
    return str.length >= 5;
  }

  handleIsGTIN(str: string) {
    if (!str) {
      return false;
    }
    return str.length >= 10;
  }

  handleHasWhiteSpace(str: string) {
    if (!str) {
      return null;
    }
    return /\s/g.test(str);
  }

  handleStrToArrayParse(str: string) {
    if (!str) {
      return null;
    }
    let parsedValue = JSON.parse(str, (_, value) => JSON.parse(value));
    return parsedValue.join(", ");
  }

  parseToNumber(num) {
    num = num + " ";
    if (num.indexOf("(") > -1) {
      num = num.replace("(", "-");
    }
    num = Number(
      num
        .replace(/\D+$/g, "")
        .replace(/[,| |\$]/g, "")
        .replace(/[,| |\%]/g, "")
    );
    return isNaN(num) ? 0 : num;
  }

  changeBreadcrumb(route: ActivatedRouteSnapshot, name: string) {
    this.breadcrumbService.changeBreadcrumb(route, name);
  }

  patchSchemaOptions(schema: any[], mapOptions: SchemaOptionValue) {
    schema.forEach((item: any) => {
      if (item.type == "panel") {
        this.patchSchemaOptions(item.components, mapOptions);
      } else {
        const options = Utils.resolveProp(item, "data.values") || [];
        const selectedMapOptions = mapOptions[item.key];

        if (
          selectedMapOptions &&
          ["dropdown"].indexOf(item.type) != -1 &&
          options.length == 0
        ) {
          if (!item.hasOwnProperty("data")) {
            item.data = {};
          }

          item.data.values = selectedMapOptions.options;
        }
      }
    });

    return schema;
  }

  onListen(type: string) {
    return this.subject$.pipe(
      filter((event: BaseEvent) => event.type === type),
      map((event) => event.payload)
    );
  }

  dispatchValue(type: string, payload: any) {
    this.subject$.next({ type, payload });
  }
}
