import { OrderPickupChange } from "./../../layout/order/ITypes";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";

import { ConfirmDialogService } from "./../confirm-dialog/confirm-dialog.service";
import {
  ApiService,
  HttpQueryService,
  SiteConfigService,
} from "src/app/core/services";
import { GeneralService } from "./general.service";
import { orderFormSchema } from "src/app/layout/order/write/schema";
import { Calendar, Utils } from "./../utils";
import { _deepClone } from "src/app/layout/project/utils";
import { AppConstant } from "./../app.constant";
import {
  DELIVERY_METHOD,
  ICollicoEmittable,
  ORDER_ROW_CHANGE_TYPE,
  ORDER_STATUS,
} from "./../ITypes";
import { ICollicoError, IQueryParams } from "src/app/core/ITypes";
import {
  IOrderReclamationGroup,
  OrderPanelFormType,
} from "src/app/layout/order/ITypes";
@Injectable()
export class OrderService {
  form = [];
  private orderDetail = new Subject();
  private _selectedOrder;
  statusFilterMode = { mode: "default", searchPath: "default.O900.key" };

  constructor(
    private api: ApiService,
    private generalService: GeneralService,
    private httpQueryService: HttpQueryService,
    private confirmDialogService: ConfirmDialogService,
    private siteConfigService: SiteConfigService,
    private toastr: ToastrService
  ) {}

  set selectedOrder(val) {
    this._selectedOrder = val;
  }

  get selectedOrder() {
    return this._selectedOrder;
  }

  async getOrders(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const offset = queryParams.offset;
      const search = queryParams.search?.trim();
      const orderStatus = queryParams.status;
      const startDate = queryParams.startDate;
      const endDate = queryParams.endDate;
      const direction = queryParams.direction;
      const order = queryParams.order as string;
      const uuid = queryParams.uuid;
      const filter = [];

      const mappedKeys = {
        [order]: `default.${order}`,
      };

      if (uuid) {
        filter.push({ key: "user_uuid", value: uuid });
      }

      const queryString = this.httpQueryService.serialize(
        {
          limit,
          skip: offset,
          direction,
          order,
          filter,
          customSearch: {
            keys: ["default.O001", "default.O028"],
            input: search,
          },
        },
        { mappedKeys }
      );

      //let apiEndpoint = `/order?limit=${limit}&skip=${offset}&order=default.${order}&direction=${direction}`;
      let apiEndpoint = `/order?${queryString}`;

      if (orderStatus) {
        const selectedOrderStatus = this.selectOrderStatusValue(orderStatus);
        const searchQueryVal = this.httpQueryService.getValue(
          selectedOrderStatus,
          this.statusFilterMode.searchPath,
          { filterType: "search" }
        );

        //apiEndpoint += `&search=default.O900.key=${orderStatus}`;
        apiEndpoint += `&${searchQueryVal}`;
      }
      if (startDate && endDate) {
        apiEndpoint += `&between=created_at=${startDate},${endDate}`;
      }

      const { status, result, meta } = await this.api
        .get(apiEndpoint)
        .toPromise();

      //this.setStatusFilterMode(result);

      if (status === 200 && result) {
        let data = [];
        for (let i = 0; i < result.length; i++) {
          const res = result[i];

          let selected = res.properties;
          let props = { ...selected.default, amount: selected.amount };

          if (props) {
            // Customer Name
            if (props.O020) {
              props.O020 = props.O020 + " " + props.O021;
            }

            // Receiver Name
            if (props.O030) {
              props.O030 = props.O030 + " " + props.O031;
            }

            // Delivery Method
            if (props.O010 && props.O011) {
              props.O010 = `${props.O010} (${props.O011})`;
            }

            // Amount
            if (props.amount) {
              props.amount = parseFloat(props.amount);
              props.amount = this.generalService.parseAmount(props.amount);
            }

            // Delivery Date
            if (
              selected.delivery_details &&
              !Array.isArray(selected.delivery_details)
            ) {
              if (selected.editable) {
                if (selected.editable.sync) {
                  props.delivery_datetime =
                    selected.editable.sync.delivery_details.starttime;
                } else {
                  props.delivery_datetime =
                    selected.delivery_details.date_start;
                }
              } else {
                if (
                  AppConstant.postalDeliveryMethod.smartship.indexOf(
                    selected.delivery_code
                  ) != -1
                ) {
                  props.delivery_datetime =
                    selected.delivery_details.expected_delivery_date;
                } else {
                  props.delivery_datetime =
                    selected.delivery_details.date_start;
                }
              }
            }

            //Order Status
            if (props.O900) {
              props.tracking_status = props.O900.label || props.O007;
            } else {
              props.tracking_status = props.O007;
            }
          }

          data.push({
            id: res.id,
            ...props,
          });
        }

        return { result: data, meta };
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getOrder(id: string) {
    try {
      const { status, result } = await this.api
        .getById(null, `/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const order = { ...result.properties };
        const collicoErrors: ICollicoError[] = result.errors;

        if(order.default.O003){
          order.default.O003 = this.generalService.parseUTCToLocalFormat(order.default.O003);
        }

        if(order.default.O013){
          order.default.O013 = this.generalService.parseUTCToLocalFormat(order.default.O013);
        }

        if(order.default.O014){
          order.default.O014 = this.generalService.parseUTCToLocalFormat(order.default.O014);
        }

        if(order?.delivery_details?.expected_delivery_date){
          order.delivery_details.expected_delivery_date = this.generalService.parseUTCToLocalFormat(order?.delivery_details?.expected_delivery_date);
        }

        this.selectedOrder = {
          order_id: result.id,
          ...order,
          errors: collicoErrors,
        };

        let deliveryDate;
        let deliveryAdditionalFields = [
          {
            field: "delivery_date",
            format: "YYYY-MM-DD",
            mappedFrom: "O013",
          },
          { field: "fromtime", format: "HH:mm:ss", mappedFrom: "O013" },
          { field: "totime", format: "HH:mm:ss", mappedFrom: "O014" },
        ];

        if (order.emittable) {
          if (order.emittable.sync) {
            deliveryDate = order.emittable.sync.delivery_details;
          } else {
            deliveryDate = order.default;
          }
        } else {
          deliveryDate = order.default;
        }

        if (order.__internal) {
          const pattern = new RegExp("(Failed).*");
          const isFailedState = pattern.test(order.default.O007);

          Object.keys(order.__internal).forEach((internalKey) => {
            const internal = order.__internal[internalKey];

            if (
              internal &&
              internal.errorState &&
              internal.errorState.errorState &&
              internal.errorState.errorState.messages &&
              internal.errorState.errorState.messages.length
            ) {
              if (!order.errorState) {
                if (Array.isArray(internal.errorState.errorState.messages)) {
                  order.errorState = {
                    message: internal.errorState.errorState.messages[0].message,
                    message_list: internal.errorState.errorState.messages.map(
                      (item) => item.message
                    ),
                  };
                } else if (
                  typeof internal.errorState.errorState.messages === "string"
                ) {
                  /* order.errorState = {
                    message: internal.errorState.errorState.messages,
                    message_list: [internal.errorState.errorState.messages],
                  }; */
                }
              }
            }
          });
        }

        //Mapped additional fields inside the delivery_details object
        if (order.delivery_details && !Array.isArray(order.delivery_details)) {
          deliveryAdditionalFields.forEach((item) => {
            if (deliveryDate[item.mappedFrom]) {
              order.delivery_details[item.field] = moment(
                deliveryDate[item.mappedFrom]
              ).format(item.format);
            } else {
              order.delivery_details[item.field] = null;
            }
          });
        }

        //Order Status
        if (order.default.O900) {
          order.tracking_status =
            order.default.O900.label || order.default.O007;
        } else {
          order.tracking_status = order.default.O007;
        }

        order.cancellable = this.canCancelOrder();
        order.productRows = this.getRequestedProduct(order);
        order.requestedProducts = this.getOnlyRequestedProducts(order);
        order.reclaimedProducts = this.haveReclaimedProducts(order);
        order.reimbursedProducts = this.haveReimbursedProducts(order);
        order.reclaimable = this.canReclaimOrder(order);
        order.editablePickup = this.canPickupOrder();

        this.selectedOrder = {
          ...this.selectedOrder,
          ...order,
          errors: collicoErrors,
        };

        return this.selectedOrder;
      }
    } catch (error) {
      throw error;
    }
  }

  isOrderFailed(status) {
    const pattern = new RegExp("(Failed).*");
    const isFailedState = pattern.test(status);
    return isFailedState;
  }

  async updateOrder(id: string, data) {
    try {
      const { status, result } = await this.api.put(data, `/${id}`).toPromise();
      if (status === 200 && result) {
        return result;
      }
    } catch (error) {
      throw error;
    }
  }

  async getDeliveryMethods() {
    try {
      const { status, result } = await this.api
        .get("/delivery-method")
        .toPromise();

      if (status === 200 && result) {
        return result.map((r) => r.properties);
      }
    } catch (error) {
      throw error;
    }
  }

  async getOrderFormSchema(selectedOrder) {
    try {
      const { status, result } = orderFormSchema;
      // const { status, result } = await this.api
      //   .get("/form/product")
      //   .toPromise();

      if (status === 200 && result && result.properties) {
        const panels: Array<any> = result.properties.components;
        this.form = panels;
        this.form = panels.map((panel) => {
          if (panel.ref != OrderPanelFormType.SCHEDULE) {
            return panel;
          }

          return panel;
        });

        return this.form;
      }
    } catch (error) {
      throw error;
    }
  }

  updateOrderDetailState(type, value) {
    this.orderDetail.next({ type, value });
  }

  processOrderState(type, component, dataset, prop = "") {
    const originalState = dataset;

    const changeState = (type, component, dataset, prop = "") => {
      Object.keys(dataset).forEach((propKeyItem) => {
        if (
          typeof dataset[propKeyItem] === "object" &&
          dataset[propKeyItem] !== null
        ) {
          if (prop) {
            prop = `${prop}.${propKeyItem}`;
          } else {
            prop = propKeyItem;
          }
          changeState(type, component, dataset[propKeyItem], prop);
          prop = "";
        } else {
          if (component.key === propKeyItem) {
            if (
              component.belongs &&
              component.belongs.relation.type == "datetime"
            ) {
              const oldVal = Utils.getPropByString(dataset, propKeyItem);
              //console.log("oldVal======>", oldVal);
              const dateOptions = ["DD.MM.YYYY", "YYYY-MM-DD"];
              let _date = this.generalService.parseDateFormat(
                oldVal,
                "YYYY-MM-DD"
              );
              let _time = this.generalService.parseDateFormat(
                oldVal,
                "HH:mm:ss"
              );

              let _result;

              if (component.belongs.relation.date) {
                _time = this.generalService.parseDateFormat(
                  component.default,
                  "HH:mm:ss"
                );
                const referenceDate = Utils.getPropByString(
                  originalState,
                  component.belongs.relation.date
                );
                _date = moment(referenceDate, dateOptions).format("YYYY-MM-DD");
              } else {
                _date = moment(component.default, dateOptions).format(
                  "YYYY-MM-DD"
                );
              }
              _result = _date + "T" + _time;
              Utils.updateObjProp(dataset, propKeyItem, _result);
              component.default = _result;
              _result = _date + "T" + _time;
            } else {
              Utils.updateObjProp(dataset, propKeyItem, component.default);
            }
          }
        }
      });
    };

    changeState(type, component, originalState, prop);
    //this.updateOrderDetailState(type, dataset);
    //console.log("type", type);
  }

  getOrderDetailState() {
    return this.orderDetail.asObservable();
  }

  async saveOrder(data, id = null) {
    /* console.log("data=========", data);
    return; */
    try {
      let requestUrl = `/order`;
      let response;
      let result;
      let status;
      let responseMsg;

      data = JSON.parse(JSON.stringify(data));
      this.handleFormatting(data);

      const payload = {
        properties: {
          ...data,
        },
        objectName: "order",
        id,
      };

      if (id) {
        response = await this.api.patch(payload, `/order`).toPromise();
        if (response.result) {
          if (Array.isArray(response.result)) {
            status = response.status;
            result = response.result[0];
          } else {
            status = response.status;
            result = response.result;
          }
          const errors = result.errors;
          this.handleError(errors);
        }
        responseMsg = `Order has been updated successfully`;
      }

      if (status === 200 && result) {
        const props = result.properties;
        props["id"] = result.id;
        return {
          data: props,
          message: responseMsg,
        };
      } else {
        return {
          data: null,
          message: "Something went wrong",
          status: false,
        };
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  handleError(errors) {
    try {
      if (Array.isArray(errors) && errors.length > 0) {
        const firstError = errors[0];
        let firstErrMsg;
        if (
          Array.isArray(firstError.message) &&
          firstError.message.length > 0
        ) {
          firstErrMsg = firstError.message[0].message;
          throw firstError.message[0];
        } else if (typeof firstError.message == "string") {
          firstErrMsg = firstError.message;
          throw new Error(firstErrMsg);
        } else if (firstError.message) {
          firstErrMsg = firstError.message.message;
          throw new Error(firstErrMsg);
        } else {
          firstErrMsg = "Sorry order does not update. Please try again";
          throw new Error(firstErrMsg);
        }
      }
    } catch (error) {
      throw error;
    }
  }

  handleFormatting(data) {
    delete data.order_id;
  }

  isDeliveryMethodAllow(deliveryCode, methods = []) {
    const defaultMethods = [
      DELIVERY_METHOD.COLLICO,
      DELIVERY_METHOD.SMARTSHIP,
      DELIVERY_METHOD.SMARTSHIP_ALT,
      DELIVERY_METHOD.PICKUP,
    ];
    const existedMethods = defaultMethods.filter(
      (methodName) => methods.indexOf(methodName) != -1
    );

    if (!existedMethods.length) {
      methods.push(...defaultMethods);
    }
    return methods.indexOf(deliveryCode) !== -1;
  }

  canOrderEdit(order, allowedDeliveries = null) {
    const lockedDeliveryTypes = [
      ORDER_STATUS.DELIVERY_FAILED,
      ORDER_STATUS.PAYMENT_FAILED,
      ORDER_STATUS.COMPLETED,
      ORDER_STATUS.CANCELLED,
    ];
    const lockedState = order.orderState && order.orderState.isLocked;
    const isAllowedDelivery = this.isDeliveryMethodAllow(
      order.delivery_code,
      allowedDeliveries || []
    );

    if (!order.default.O900) {
      return false;
    }

    if (
      !isAllowedDelivery ||
      lockedDeliveryTypes.indexOf(order.default.O900.key) != -1
      //this.isOrderFailed(order.default.O007)
    ) {
      return false;
    } else if (lockedState) {
      return false;
    } else {
      return true;
    }
  }

  accessCollicoEmittableData(order): ICollicoEmittable {
    const notExistedCriteria = [
      ORDER_STATUS.NEW_ORDER,
      ORDER_STATUS.CONFIRMATION_SENT,
    ];
    const existedCriteria = [ORDER_STATUS.NEW];

    if (!order.default.O900) {
      return {
        key: "emittable",
        exist: false,
      };
    }

    //Case 1: If order does not exist in Collico
    if (notExistedCriteria.indexOf(order.default.O900.key) != -1) {
      return {
        data: order.emittable,
        key: "emittable",
        exist: false,
      };
    }
    //Case 2: If order exist in Collico
    else if (existedCriteria.indexOf(order.default.O900.key) != -1) {
      return {
        data: (order.emittable && order.emittable.sync) || null,
        key: "emittable.sync",
        exist: true,
      };
    }
    // No criteria matched
    else {
      return {
        key: "emittable",
        exist: false,
      };
    }
  }

  get statuses() {
    return AppConstant.orderStaus;
  }

  get statusGroups() {
    return AppConstant.orderStatusDropdown;
  }

  setStatusFilterMode(orders) {
    const hasStatus = orders.find((item) => {
      if (item.hasOwnProperty("properties")) {
        return item.properties.default.hasOwnProperty("O900");
      } else {
        return item.hasOwnProperty("O900");
      }
    });

    if (hasStatus) {
      this.statusFilterMode = {
        mode: "default",
        searchPath: "default.O900.key",
      };
    } else {
      this.statusFilterMode = { mode: "fallback", searchPath: "default.O007" };
    }
  }

  selectOrderStatusValue(key) {
    const stautses = this.statusGroups;
    const filterMode = this.statusFilterMode.mode;

    const foundStatusType = this.statusGroups.find((item) => {
      return item.value == key;
    });

    if (foundStatusType) {
      return AppConstant.orderStatusGroup[filterMode][key];
    }
    return;
  }

  haveReclaimedProducts(order) {
    if (Array.isArray(order.products) && order.products.length) {
      return order.products
        .filter((item) => {
          return item.changeType == ORDER_ROW_CHANGE_TYPE.RECLAMATION;
        })
        .map((item, index) => {
          if (order.default) {
            item.reclaim_id = `${order.default.O001}-${(index + 1).toString()}`;
          }
          const reason = AppConstant.orderReasons.find(
            (reasonItem) => reasonItem.value == item.orderRowChangeType
          );

          item.reason_label = reason ? reason.label : "";
          return item;
        });
    }
    return [];
  }

  haveReimbursedProducts(order) {
    if (Array.isArray(order.products) && order.products.length) {
      return order.products.filter((item) => {
        return (
          item.difference &&
          item.difference.compensationType ==
            ORDER_ROW_CHANGE_TYPE.REIMBURSEMENT
        );
      });
    }
    return [];
  }

  getRequestedProduct(order, type: ORDER_ROW_CHANGE_TYPE = null): Array<any> {
    if (Array.isArray(order.products) && order.products.length) {
      return order.products.filter((item) => {
        if (type) {
          return item.changeType == type;
        } else {
          if (item.changeType != ORDER_ROW_CHANGE_TYPE.RECLAMATION) {
            return true;
          }
        }
      });
    }
    return [];
  }

  getOnlyRequestedProducts(_order = null): Array<any> {
    const order = _order || this.selectedOrder;

    if (Array.isArray(order.products) && order.products.length) {
      return order.products.filter((item) => {
        const isReimbursedProduct =
          item.difference &&
          item.difference.compensationType ==
            ORDER_ROW_CHANGE_TYPE.REIMBURSEMENT;
        const isReclaimedProduct =
          item.changeType == ORDER_ROW_CHANGE_TYPE.RECLAMATION;

        if (isReclaimedProduct || isReimbursedProduct) {
          return false;
        }

        return true;
      });
    }
    return [];
  }

  getReclamationGroups(_order): Array<any> {
    const order = _order || this.selectedOrder;
    const reclamations = _deepClone(this.haveReclaimedProducts(order));
    const reclamationGroups: IOrderReclamationGroup[] = [];

    if (Array.isArray(reclamations) && reclamations.length) {
      reclamations.forEach((product, index) => {
        product = Utils.cloneObj(product);
        const reclamationID = product.default && product.default.R006;
        const groupExisted = reclamationGroups.find((groupItem) => {
          return reclamationID == groupItem.reclamationID;
        });
        const reason = AppConstant.orderReasons.find(
          (reasonItem) => reasonItem.value == product.orderRowChangeType
        );
        const date =
          product.default && product.default.R003
            ? this.generalService.formatDateToString(product.default.R003)
            : "";
        const invoiceID = reclamationID || "X";

        product.reason_label = reason ? reason.label : "";

        if (groupExisted) {
          groupExisted.products.push(product);
          return;
        }

        reclamationGroups.push({
          _id: index + 1,
          reclamationID,
          invoiceID,
          date,
          title: `${order.default.O001}-${reclamationID}`,
          products: [product],
        });
      });
    }

    return reclamationGroups;
  }

  findReclamationsByProduct(productID, _order = null): Array<any> {
    const order = _order || this.selectedOrder;
    return order.reclaimedProducts.filter(
      (item) => item.product_id == productID
    );
  }

  findReimbursementsByProduct(productID, _order = null): Array<any> {
    const order = _order || this.selectedOrder;
    return order.reimbursedProducts.filter((item) => {
      return item.product_id == productID;
    });
  }

  getRevisedQtyByProduct(productID, type, _order = null): Number {
    const order = _order || this.selectedOrder;
    let selectedAllRevisedProducts = [];

    if (type == ORDER_ROW_CHANGE_TYPE.RECLAMATION) {
      selectedAllRevisedProducts = this.findReclamationsByProduct(
        productID,
        order
      );
    } else if (type == ORDER_ROW_CHANGE_TYPE.REIMBURSEMENT) {
      selectedAllRevisedProducts = this.findReimbursementsByProduct(
        productID,
        order
      );
    }

    return selectedAllRevisedProducts.length > 0
      ? selectedAllRevisedProducts.reduce((acc, item) => {
          return Number(item.default.R020) + acc;
        }, 0)
      : 0;
  }

  getMaxAvailableQty(product, _order = null): Number {
    const order = _order || this.selectedOrder;

    const reclaimQty = this.getRevisedQtyByProduct(
      product.product_id,
      ORDER_ROW_CHANGE_TYPE.RECLAMATION,
      order
    );

    const reimburseQty = this.getRevisedQtyByProduct(
      product.product_id,
      ORDER_ROW_CHANGE_TYPE.REIMBURSEMENT,
      order
    );

    const maxQty =
      product.default.R020 - Math.abs(+reclaimQty) - Math.abs(+reimburseQty);

    const actualMaxQty = Utils.toNumber(
      maxQty.toFixed(8).replace(/\.?0+$/, "")
    );

    return actualMaxQty;
  }

  isReimburseProduct(productID) {
    if (this.selectedOrder.products && this.selectedOrder.products.length) {
      return this.selectedOrder.products.find((item) => {
        const difference = item.difference;
        return (
          difference &&
          difference.compensationType == ORDER_ROW_CHANGE_TYPE.REIMBURSEMENT &&
          // difference.collectedAmount &&
          item.product_id == productID
        );
      });
    }
    return null;
  }

  isReclaimProduct(productID, _order = null) {
    const order = _order || this.selectedOrder;

    if (order.products && order.products.length) {
      return order.products.find((item) => {
        return (
          item.product_id == productID &&
          item.changeType == ORDER_ROW_CHANGE_TYPE.RECLAMATION
        );
      });
    }
    return null;
  }

  getNonReclaimedAndReimbursedProducts = (_order) => {
    const order = _order || this.selectedOrder;
    const products = this.getOnlyRequestedProducts(order);

    return _deepClone(products)
      .map((orderedProduct) => {
        orderedProduct.max_available_qty = this.getMaxAvailableQty(
          orderedProduct,
          order
        );

        orderedProduct.qtyRemained =
          orderedProduct.max_available_qty &&
          orderedProduct.max_available_qty != 0
            ? true
            : false;

        return orderedProduct;
      })
      .filter((orderedProduct) => {
        return orderedProduct.max_available_qty > 0;
      });
  };

  async cancelOrder(orderID, successCallback = null, progressCallback) {
    this.confirmDialogService.open(
      {
        title: "Confirmation",
        content: `Are you sure you want to cancel this order?`,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      },
      async () => {
        const payload = Utils.setPropByPath(
          { objectName: "order", id: orderID },
          "properties.emittable.sync.orderState.status",
          "cancelled"
        );
        /* const payload = {
          properties: {
            status: "cancelled",
          },
          objectName: "order",
          id: orderID,
        }; */
        try {
          progressCallback(true);
          const { status, result } = await this.api
            .patch(payload, `/order`)
            .toPromise();

          if (status === 200 && result) {
            progressCallback(false);

            this.handleError(result.errors);

            successCallback();
            this.toastr.success("Order has been cancelled successfully");
          }
        } catch (error) {
          progressCallback(false);
          this.toastr.error(error.message, "Order Error");
          console.error(error);
        }
      },
      () => {
        //console.error(error);
        return new Promise((resolve) => {
          resolve(false);
        });
      }
    );
  }

  canCancelOrder(order = null) {
    if (this.selectedOrder) order = this.selectedOrder;

    if (order && order.default && order.default.O900) {
      const cancelledCriteria = [
        ORDER_STATUS.PAYMENT_RECEIVED,
        ORDER_STATUS.NEW_ORDER,
        ORDER_STATUS.CONFIRMATION_SENT,
        ORDER_STATUS.NEW,
      ];
      return cancelledCriteria.indexOf(order.default.O900.key) != -1;
    }
    return false;
  }

  canReclaimOrder(_order = null) {
    const order = _order || this.selectedOrder;

    if (order && order.default && order.default.O900) {
      const reclaimCriteria = [
        ORDER_STATUS.DELIVERY_DISPATCHED,
        ORDER_STATUS.DELIVERY_CONFIRMATION_SENT,
        ORDER_STATUS.ORDER_REIMBURSED,
        ORDER_STATUS.COMPLETED,
      ];
      const hasReclaimedFound =
        reclaimCriteria.indexOf(order.default.O900.key) != -1;

      if (hasReclaimedFound) {
        const isReclamationDurationApplicable =
          this.validateReclamationTimeframe();

        if (isReclamationDurationApplicable === false) {
          return false;
        }

        const nonValidProducts =
          this.getNonReclaimedAndReimbursedProducts(order);

        const reclaimedProductCriteria =
          nonValidProducts.length > 0 && isReclamationDurationApplicable;

        if (reclaimedProductCriteria) {
          return true;
        }

        return false;
      }

      return false;
    }
    return false;
  }

  canPickupOrder(order = null) {
    if (this.selectedOrder) order = this.selectedOrder;

    if (order.orderPickupChange && order.orderPickupChange.is_completed) {
      return false;
    }

    if (order && order.default && order.default.O900) {
      const cancelledCriteria = [
        ORDER_STATUS.PAYMENT_RECEIVED,
        ORDER_STATUS.NEW_ORDER,
        ORDER_STATUS.CONFIRMATION_SENT,
        ORDER_STATUS.NEW,
      ];
      return cancelledCriteria.indexOf(order.default.O900.key) != -1;
    }
    return false;
  }

  validateReclamationTimeframe(_order = null) {
    const order = _order || this.selectedOrder;

    const reclamationValidityDays = this.siteConfigService.getItem(
      "order.reclaimingPeriodDays"
    );

    if (reclamationValidityDays) {
      const orderDeliveryDate = this.generalService.formatDateTimeToUTC(
        order.default.O013
      );

      return (
        Calendar.getDaysDuration(orderDeliveryDate) <= reclamationValidityDays
      );
    }

    return true;
  }
}
